<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-check-box
            v-if="
              loggedInUser &&
                loggedInUser.id &&
                (loggedInUser.id === 38 || loggedInUser.id === 1)
            "
            v-model="autoRefresh"
            :busy="busy"
            label="Auto Refresh"
            style="display:inline-block; margin-top: 4px"
          />

          <o-drawer
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            :visible="showFilter"
            width="50vw"
            @on-item-search="reset()"
            @on-item-reset="reset(true)"
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.account_id"
                  :busy="dropBusy"
                  label="Account"
                  :items="accounts"
                  @on-change="getAdvertisers()"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.advertiser_id"
                  :busy="dropBusy"
                  label="Advertisers"
                  :items="advertisers"
                />
              </v-col>
              <v-col cols="12">
                <o-input
                  v-model="parameters.creative_id"
                  :busy="dropBusy"
                  label="Creative ID"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.active"
                  :busy="dropBusy"
                  label="Creative Status"
                  :items="actives"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.failed"
                  :busy="dropBusy"
                  label="Status"
                  :items="fails"
                />
              </v-col>
            </v-row>
          </o-drawer>

          <o-drawer            
            :auto-close="true"
            :actions="[]"
            button-icon="mdi-filter-plus-outline"
            :title="`LINE ITEMS`"
            :use-button="false"
            width="50vw"
            :visible="showLineItems"
            @on-close="showLineItems = false"
          >
            <o-table
              ref="line_item_table"
              type="rest"
              :has-paging="true"
              :rest="{
                url: `/rest/creative_line_items?platform_id=1&account_id=${lineItemsParameters.account_id}&creative_id=${lineItemsParameters.creative_id}`,
                payload: null,
              }"
              :height="getHeight(300)"
              :fixed-header="true"
              :headers="[
                {
                  text: 'ID',
                  align: 'end',
                  sortable: false,
                  value: 'line_item_id',
                  type: 'text',
                },
                {
                  text: 'Name',
                  align: 'start',
                  sortable: false,
                  value: 'line_item_name',
                  type: 'text',
                },
                {
                  text: 'Start Date',
                  align: 'start',
                  sortable: false,
                  value: 'start_date',
                  type: 'date-time',
                },
                {
                  text: 'End Date',
                  align: 'start',
                  sortable: false,
                  value: 'end_date',
                  type: 'date-time',
                },
                {
                  text: 'Active',
                  align: 'center',
                  value: 'active',
                  type: 'boolean',
                },
                {
                  value: 'action',
                  type: 'action',
                  actions: [
                    {
                      key: 'on-line-item-click',
                      label: 'View',
                      icon: 'mdi-page-next-outline',
                      color: 'success',
                    },
                  ],
                },
              ]"
              @set-busy="setBusy"
              @on-line-item-click="lineItemClick"
            >
              <template v-slot:slot="row">
                <div v-if="row.prop.key === 'notes'">
                  {{ row.prop.item.notes }}
                </div>
              </template>
            </o-table>
          </o-drawer>

          <o-drawer
            :auto-close="true"
            :actions="[]"
            button-icon="mdi-filter-plus-outline"
            :title="`Approval History`"
            :use-button="false"
            width="75vw"
            :visible="showApprovalHistories"
            @on-close="showApprovalHistories = false"
          >
            <o-table
              :busy="busy"
              ref="creative_approval_table"
              type="rest"
              :has-paging="true"
              :rest="{
                url: `/rest/creative_approval_queue_histories?platform_id=1&account_id=${approvalHistoriesParameters.account_id}&creative_approval_id=${approvalHistoriesParameters.creative_approval_id}`,
                payload: null,
              }"
              :height="getHeight(300)"
              :fixed-header="true"
              :headers="[
                {
                  text: 'ID',
                  align: 'end',
                  sortable: false,
                  value: 'id',
                  type: 'text',
                },
                {
                  text: 'Action',
                  align: 'start',
                  sortable: false,
                  value: 'account_id',
                  type: 'slot',
                },
                {
                  text: 'Name',
                  align: 'start',
                  sortable: false,
                  value: 'approval_status_message',
                  type: 'slot',
                },
                {
                  text: 'Request Date',
                  align: 'start',
                  sortable: false,
                  value: 'request_date',
                  type: 'date-time',
                },
                {
                  text: 'Update Date',
                  align: 'start',
                  sortable: false,
                  value: 'update_date',
                  type: 'date-time',
                },
                {
                  text: 'Active',
                  align: 'center',
                  value: 'approved',
                  type: 'boolean',
                },
              ]"
            >
              <template v-slot:slot="row">
                <div v-if="row.prop.key === 'account_id'">
                  {{ row.prop.item.action }}
                </div>
                <div v-if="row.prop.key === 'approval_status_message'">
                  <div
                    v-if="
                      row.prop.item.approval_status_message &&
                        row.prop.item.approval_status_message.message
                    "
                  >
                    {{ row.prop.item.approval_status_message.message }}
                  </div>
                </div>
              </template>
            </o-table>
          </o-drawer>

          <o-drawer 
            :auto-close="true"
            :actions="[]"
            button-icon="mdi-filter-plus-outline"
            :title="`Media Files`"
            :use-button="false"
            width="75vw"
            :visible="showMediaFiles"
            @on-close="showMediaFiles = false"
          >
            <o-table
              ref="media_files_table"
              type="rest"
              method="post"
              :has-paging="false"
              :rest="{
                url: `/rest/intranet/list`,
                payload: mediaFilesParameters,
              }"
              :height="getHeight(300)"
              :fixed-header="true"
              :headers="[
                {
                  text: 'ID',
                  align: 'end',
                  sortable: false,
                  value: 'media_file_id',
                  type: 'text',
                },
                {
                  text: 'Format',
                  align: 'start',
                  sortable: false,
                  value: 'format',
                  type: 'text',
                },
                {
                  text: 'MIME Type',
                  align: 'start',
                  sortable: false,
                  value: 'mime_type',
                  type: 'text',
                },
                {
                  text: 'Bit Rate',
                  align: 'start',
                  sortable: false,
                  value: 'bit_rate',
                  type: 'text',
                },
                {
                  text: 'Width',
                  align: 'start',
                  sortable: false,
                  value: 'width',
                  type: 'text',
                },
                {
                  text: 'Height',
                  align: 'start',
                  sortable: false,
                  value: 'height',
                  type: 'text',
                },
                {
                  text: 'URL',
                  align: 'start',
                  sortable: false,
                  value: 'media_url',
                  type: 'slot',
                },
              ]"
            >
              <template v-slot:slot="row">
                <div
                  v-if="row.prop.key === 'media_url'"
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  <a :href="row.prop.item.media_url" target="_blank">
                    {{ row.prop.item.media_url }}</a
                  >
                </div>
              </template>
            </o-table>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider />

      <v-card-text class="text-primary">
        <o-table
          ref="table"
          :busy="true"
          type="rest"
          :has-paging="true"
          :height="getHeight(450)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/execute?time=${new Date().getTime()}`,
            payload: {
              topic: '',
              name: 'check_creative_status',
              params: {
                a01: parameters.account_id,
                a02: parameters.advertiser_id,
                a03: parameters.creative_id,
                a04: parameters.failed,
                a05: parameters.active,
                a06: parameters.source_name,
                a07: parameters.primary_asset,
                cnt: parameters.cnt,
                starts: parameters.starts,
              },
            },
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              sortable: false,
              value: 'row_no',
              type: 'text',
            },
            {
              text: 'Account',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Advertiser',
              align: 'start',
              sortable: false,
              value: 'advertiser_name',
              type: 'slot',
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'creative_name',
              type: 'slot',
            },
            {
              text: 'Type',
              align: 'start',
              sortable: false,
              value: 'creative_type_name',
              type: 'text',
            },
            {
              text: 'Active',
              align: 'center',
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Start Date',
              align: 'start',
              value: 'start_date',
              type: 'date-time',
            },
            {
              text: 'End Date',
              align: 'start',
              value: 'end_date',
              type: 'date-time',
            },
            {
              text: 'Created Date',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              text: 'Created By',
              align: 'start',
              sortable: false,
              value: 'created_by_name',
              type: 'slot',
            },
            {
              text: 'Approval',
              align: 'center',
              value: 'creative_approval_id',
              type: 'slot',
            },
            {
              text: 'Associated Line Items',
              align: 'center',
              value: 'associated_line_items',
              type: 'slot',
            },
            {
              text: 'Asset ID',
              align: 'center',
              sortable: false,
              value: 'primary_asset',
              type: 'slot', 
            },
            {
              text: 'Media Files',
              align: 'center',
              value: 'media_files_count',
              type: 'slot',
            },
            {
              text: 'Status',
              align: 'center',
              value: 'asset_status_name',
              type: 'slot',
            },
            {
              text: 'General Status',
              align: 'start',
              value: 'encoding_job_id',
              type: 'slot',
            },
          ]"
          @set-busy="setBusy"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.account_name }} ({{ row.prop.item.account_id }})
            </div>
            <div
              v-if="row.prop.key === 'advertiser_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.advertiser_name }} ({{
                row.prop.item.advertiser_id
              }})
            </div>
            <div
              v-if="row.prop.key === 'creative_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.creative_name }} ({{
                row.prop.item.creative_id
              }})
            </div>
            <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'asset_status_name'"
              class="text-center"
            >
              <v-chip
                class=""
                :color="
                  row.prop.item.asset_status_name == 'Encoded'
                    ? 'success'
                    : [
                        'Queue',
                        'Transcoding HIDEF',
                        'HIDEF transcoded',
                        'Transcoding Last Formats',
                      ].includes(row.prop.item.asset_status_name)
                    ? 'orange darken-1'
                    : 'danger'
                "
                label
                small
                text-color="white"
              >
                <v-icon small left>
                  {{
                    row.prop.item.asset_status_name == "Encoded"
                      ? "mdi-check"
                      : [
                          "Queue",
                          "Transcoding HIDEF",
                          "HIDEF transcoded",
                          "Transcoding Last Formats",
                        ].includes(row.prop.item.asset_status_name)
                      ? "mdi-wrench"
                      : "mdi-close"
                  }}
                </v-icon>
                {{ row.prop.item.asset_status_name }} ({{
                  row.prop.item.asset_status
                }})
              </v-chip>
            </div>
            <div v-if="row.prop.key === 'encoding_job_id'">
              <div
                v-if="
                  row.prop.item.asset_status != 2 &&
                    row.prop.item.encoding_job_id
                "
              >
                <o-subscribe
                  :has-job="true"
                  :keys="['jobStatus', 'message']"
                  :find-id="row.prop.item.encoding_job_id"
                  :query="
                    getCreativeSubscription(row.prop.item.encoding_job_id)
                  "
                >
                  <template v-slot:queryContainer="d">
                    <template
                      v-if="
                        d.prop &&
                          d.prop.queryData &&
                          d.prop.queryData.jobById &&
                          d.prop.queryData.jobById.message
                      "
                    >
                      {{ d.prop.queryData.jobById.message }}
                    </template>
                  </template>
                </o-subscribe>
              </div>
            </div>
            <div
              v-if="row.prop.key === 'associated_line_items'"  class="text-center"
            >
              <o-button
                v-if="row.prop.item.associated_line_items > 0"
                color="light-green darken-3"
                icon="mdi-animation"
                type="right-label-icon"
                :label="`${row.prop.item.associated_line_items}`"
                @on-click="showLineItemsByCreative(row.prop.item)"                
              /> 
            </div>
            <div v-if="row.prop.key === 'primary_asset'" class="text-center">
              <o-button
                v-if="row.prop.item.primary_asset > 0"
                color="blue lighten-2"
                icon="mdi-motion-play-outline"
                type="right-label-icon"
                :label="`${row.prop.item.primary_asset}`"
                @on-click="view(row.prop.item)"                
              /> 
            </div>
            <div
              v-if="row.prop.key === 'creative_approval_id'"
              class="text-center"
            >
              <a
                href="javascript:void(0);"
                @click="showApprovalHistoryByCreative(row.prop.item)"
              >
                <v-chip
                  :style="
                    row.prop.item.creative_approval_approved == '1'
                      ? 'cursor: pointer !important'
                      : ''
                  "
                  class=""
                  :color="
                    row.prop.item.creative_approval_approved == '1'
                      ? 'success'
                      : 'danger'
                  "
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>
                    {{
                      row.prop.item.creative_approval_approved == "1"
                        ? "mdi-check"
                        : "mdi-close"
                    }}
                  </v-icon>
                  {{
                    row.prop.item.creative_approval_approved == "1"
                      ? "Yes"
                      : `No`
                  }}
                  <span v-if="row.prop.item.creative_approval_approved"
                    >({{ row.prop.item.creative_approval_approved }})</span
                  >
                </v-chip>
              </a>
            </div>
            <div v-if="row.prop.key === 'media_files_count'" class="text-center">
              <o-button
                v-if="row.prop.item.media_files_count > 0"
                color="indigo lighten-2"
                icon="mdi-animation-outline"
                type="right-label-icon"
                :label="`${row.prop.item.media_files_count}`"
                @on-click="showMediaFilesByCreative(row.prop.item)"                
              /> 
            </div>
          </template>
        </o-table>
      </v-card-text>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "CreativeChecker",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Creatives",
  },
  data() {
    return {
      title: "Creatives",
      showFilter: false,
      autoRefresh: false,
      accounts: [],
      advertisers: [],
      actives: [
        { id: null, name: "N/A" },
        { id: true, name: "TRUE" },
        { id: false, name: "FALSE" },
      ],
      fails: [
        { id: 0, name: "ALL" },
        { id: 1, name: "FAILED" },
      ],
      busy: false,
      dropBusy: false,
      action: "",
      valid: false,
      parameters: {
        account_id: 0,
        advertiser_id: 0,
        creative_id: 0,
        failed: 0,
        active: null,
        source_name: "ALL",
        primary_asset: 0,
        cnt: 20,
        starts: 0,
      },
      showLineItems: false,
      lineItemsParameters: {
        account_id: 0,
        creative_id: 0,
        cnt: 20,
        starts: 0,
      },
      showApprovalHistories: false,
      approvalHistoriesParameters: {
        account_id: 0,
        creative_approval_id: 0,
        cnt: 20,
        starts: 0,
      },
      showMediaFiles: false,
      mediaFilesParameters: {
        fields: ["a.*"],
        sources: {
          main: "media_files a",
          children: [],
        },
        wheres: [],
        groups: [],
        orders: [],
      },
      model: {},
      originalModel: {},
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  watch: {},
  mounted() {
    if (this.$route.query.creative_id && this.$route.query.creative_id > 0) {
      this.parameters.creative_id = this.$route.query.creative_id;
    }

    if (this.$route.query.account_id && this.$route.query.account_id > 0) {
      this.parameters.account_id = this.$route.query.account_id;
    }

    if (this.$route.query.source_name && this.$route.query.source_name !== "") {
      this.parameters.source_name = this.$route.query.source_name;
    }

    if (
      this.$route.query.primary_asset &&
      this.$route.query.primary_asset > 0
    ) {
      this.parameters.primary_asset = parseInt(this.$route.query.primary_asset);
    }

    this.getData(`rest/accounts`, null).then((response) => {
      this.accounts.push({ id: 0, name: "All" });
      response.data.forEach((d) => {
        this.accounts.push({ id: d.id, name: d.account_name });
      });
      this.advertisers.push({ id: 0, name: "All" });
    });

    if (this.loggedInUser.id === 38 || this.loggedInUser.id === 1) {
      setInterval(async () => {
        if (this.autoRefresh && this.$refs.table) {
          this.$refs.table.refresh();
        }
      }, 60000);
    }
  },
  methods: {
    add() {
      this.action = "add";
      this.model = this.baseModel;
    },
    view(item) {
      const creative_type = item.creative_type == 1 ? "VIDEO" : "AUDIO";
      const creative_asset_id = item.primary_asset;

      this.$router.push(
        `/app/checker/assets?source_name=${creative_type}&account_id=${item.account_id}&creative_asset_id=${creative_asset_id}&show=1`
      );
    },
    reset(par) {
      this.action = "";
      this.model = this.baseModel;
      if (par) {
        this.parameters = {
          account_id: 0,
          advertiser_id: 0,
          creative_id: 0,
          source_name: "ALL",
          primary_asset: 0,
          failed: 0,
          active: null,
          cnt: 20,
          starts: 0,
        };
      }

      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
    async getAdvertisers() {
      this.advertisers = [];
      if (this.parameters.account_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/advertisers?platform_id=1&account_id=${this.parameters.account_id}&sort_by=advertiser_name&order=ASC&page_size=5000&compact=1`,
          null
        ).then((response) => {
          this.advertisers.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.advertisers.push({
              id: d.advertiser_id,
              name: d.advertiser_name,
            });
          });
          this.dropBusy = false;
        });
      } else {
        this.advertisers = [];
        this.advertisers.push({ id: 0, name: "All" });
      }
    },
    getCreativeSubscription(job_id) {
      return `subscription {
          jobStatus(id: "${job_id}") {
            message
          }
        }`;
    },
    showLineItemsByCreative(item) {
      this.lineItemsParameters = {
        account_id: item.account_id,
        creative_id: item.creative_id,
        cnt: 20,
        starts: 0,
      };
      this.showLineItems = true;
      setTimeout(async () => {
        this.$refs.line_item_table.refresh();
      }, 200);
    },
    showApprovalHistoryByCreative(item) {
      if (item.creative_approval_id && item.creative_approval_id > 0) {
        this.approvalHistoriesParameters = {
          account_id: item.account_id,
          creative_approval_id: item.creative_approval_id,
          cnt: 20,
          starts: 0,
        };
        this.showApprovalHistories = true;
        setTimeout(async () => {
          this.$refs.creative_approval_table.refresh();
        }, 200);
      }
    },
    showMediaFilesByCreative(item) {
      this.mediaFilesParameters.wheres = [
        `a.account_id = ${item.account_id}`,
        `AND a.advertiser_id = ${item.advertiser_id}`,
        `AND a.creative_id = ${item.creative_id}`,
      ];        
      
      this.showMediaFiles = true;
      setTimeout(async () => {
        this.$refs.media_files_table.refresh(); 
      }, 200);
    },
    lineItemClick(item) {
      const href = `/app/checker/line-items?line_item_id=${item.line_item_id}`;
      window.open(href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped></style>
