<template>
  <CreativeChecker dialog-name="Creatives" />
</template>
<script>
import CreativeChecker from '@/views/app/checker/Creative'

export default {
  name: 'CreativeWrapper',
  components: {
    CreativeChecker
  }
}
</script>
